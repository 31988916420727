define("discourse/plugins/discourse-audio-upload/discourse/initializers/audio-upload", ["exports", "discourse/lib/plugin-api", "discourse/components/d-editor", "discourse/lib/show-modal"], function (_exports, _pluginApi, _dEditor, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePlugin(api) {
    const siteSettings = api.container.lookup('site-settings:main');
    if (siteSettings.composer_audio_upload_enabled) {
      _dEditor.default.reopen({
        actions: {
          showAudioUploadModal: function () {
            (0, _showModal.default)('audio_upload', {
              title: 'composer_audio_upload.title'
            });
          }
        }
      });
      api.onToolbarCreate(toolbar => {
        toolbar.addButton({
          id: 'composer_audio_upload',
          group: 'extras',
          icon: 'microphone',
          action: 'showAudioUploadModal',
          title: 'composer.composer_audio_upload_button_title'
        });
      });
    }
  }
  var _default = _exports.default = {
    name: 'composer-audio-upload',
    initialize(container) {
      (0, _pluginApi.withPluginApi)('0.1', initializePlugin);
    }
  };
});