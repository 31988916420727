define("discourse/plugins/discourse-audio-upload/discourse/controllers/audio-upload", ["exports", "discourse/mixins/modal-functionality", "ember-addons/ember-computed-decorators", "discourse/lib/uploads"], function (_exports, _modalFunctionality, _emberComputedDecorators, _uploads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj, _init;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function padStart(s, l, char) {
    let n = l - String(s).length;
    for (let i = 0; i < n; ++i) s = char + s;
    return s;
  }
  function stopStream(stream) {
    stream.getAudioTracks().forEach(track => track.stop());
  }
  var _default = _exports.default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _emberComputedDecorators.default)('state', 'hasRecording'), _dec2 = (0, _emberComputedDecorators.default)('state'), _dec3 = (0, _emberComputedDecorators.default)('state'), _dec4 = (0, _emberComputedDecorators.default)('_audioEl'), _dec5 = (0, _emberComputedDecorators.default)('_audioData'), _dec6 = (0, _emberComputedDecorators.default)(), (_obj = {
    state: 'idle',
    // 'idle', 'recording', 'recording_start', 'playing', 'processing'
    isRecording: Ember.computed.equal('state', 'recording'),
    isRecordingStart: Ember.computed.equal('state', 'recording_start'),
    isPlaying: Ember.computed.equal('state', 'playing'),
    isProcessing: Ember.computed.equal('state', 'processing'),
    isIdle: Ember.computed.equal('state', 'idle'),
    hasRecording: Ember.computed.notEmpty('_audioEl'),
    disallowPlayback(state, hasRecording) {
      return state != 'idle' && state != 'playing' || !hasRecording;
    },
    disallowRecord(state) {
      return state == 'recording_start' || state != 'idle' && state != 'recording';
    },
    disallowUpload(state) {
      return state != 'idle';
    },
    recordingDuration(audio) {
      if (audio) {
        let secs = audio.duration * 1000;
        if (secs < 1000) {
          return '< 1s';
        } else {
          let d = moment.duration(secs);
          return Math.floor(d.asMinutes()) + ':' + padStart(d.seconds(), 2, '0');
        }
      }
      return '-';
    },
    recordingSize(data) {
      if (data) {
        let bytes = data.size;
        return bytes < 1024 ? bytes + ' B' : Math.round(bytes * 10 / 1024) / 10 + ' kB';
      }
      return '-';
    },
    _recorder: null,
    _audioData: null,
    _audioEl: null,
    _stream: null,
    uploadIcon: () => (0, _uploads.uploadIcon)(),
    _clearRecording: function () {
      this._recorder = null;
      this.set('_audioData', null);
      if (this._audioEl) {
        this._audioEl.remove();
        this.set('_audioEl', null);
      }
    },
    init: function () {
      this._super();
    },
    onShow: function () {
      this._clearRecording();
    },
    actions: {
      uploadFile: function () {
        if (!this._audioData) {
          this.flash('You have to record something!', 'error');
          return;
        }
        let $dialog = $('.composer-audio-upload-modal');
        $('.wmd-controls').fileupload('add', {
          files: [this._audioData]
        });
        this.send('closeModal');
      },
      startStopRecording: function () {
        if (this.state == 'idle') {
          this._clearRecording();
          this._recorder = new Microm();
          this._recorder.record().then(stream => {
            this._stream = stream;
            this.set('state', 'recording_start');
            setTimeout(() => {
              this.set('state', 'recording');
            }, 1050);
          }).catch(err => {
            this.flash('An error occured. Did you enable voice recording in your browser?');
            console.error(err);
          });
        } else if (this.state == 'recording') {
          this.set('state', 'processing');
          this._recorder.stop().then(result => {
            let blob = result.blob;
            blob.name = 'recording.mp3';
            blob.lastModifiedDate = new Date();
            let audio = document.createElement('audio');
            audio.style.display = 'none';
            $(audio).on('ended', () => {
              this.set('state', 'idle');
            }).one('timeupdate', () => {
              audio.currentTime = 0;
              this.set('_audioEl', audio);
              this.set('_audioData', blob);
              this.set('state', 'idle');
              stopStream(this._stream);
            }).on('loadedmetadata', () => {
              audio.currentTime = 48 * 3600;
            });
            audio.src = result.url;
          });
        }
      },
      startStopPlayback: function () {
        if (this.state == 'idle') {
          let audio = this._audioEl;
          audio.currentTime = 0;
          let promise = audio.play();
          if (promise && promise.then) {
            promise.then(() => {
              this.set('state', 'playing');
            }).catch(err => {
              console.error(err);
            });
          } else {
            this.set('state', 'playing');
          }
        } else if (this.state == 'playing') {
          this._audioEl.pause();
          this.set('state', 'idle');
        }
      },
      closeModal() {
        if (this.state == 'idle') this.get("modal").send("closeModal");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "disallowPlayback", [_dec], Object.getOwnPropertyDescriptor(_obj, "disallowPlayback"), _obj), _applyDecoratedDescriptor(_obj, "disallowRecord", [_dec2], Object.getOwnPropertyDescriptor(_obj, "disallowRecord"), _obj), _applyDecoratedDescriptor(_obj, "disallowUpload", [_dec3], Object.getOwnPropertyDescriptor(_obj, "disallowUpload"), _obj), _applyDecoratedDescriptor(_obj, "recordingDuration", [_dec4], Object.getOwnPropertyDescriptor(_obj, "recordingDuration"), _obj), _applyDecoratedDescriptor(_obj, "recordingSize", [_dec5], Object.getOwnPropertyDescriptor(_obj, "recordingSize"), _obj), _applyDecoratedDescriptor(_obj, "uploadIcon", [_dec6], (_init = Object.getOwnPropertyDescriptor(_obj, "uploadIcon"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj)), _obj)));
});